<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-50"
    >
      <h4>
        {{ $t('dashboard.orders.title') }}
        <small class="text-secondary">{{ $t('dashboard.orders.last-month') }}</small>
      </h4>
    </b-col>

    <b-col
      cols="12"
      md="6"
      lg="3"
    >

      <rc-overlay
        :show="loading"
        rounded
      >
        <statistic-card-horizontal
          icon="ClockIcon"
          :statistic="ordersStatisticsGrouped.toDo"
          :statistic-title="$t('dashboard.orders.to-do')"
          color="success"
        />
      </rc-overlay>

    </b-col>

    <b-col
      cols="12"
      md="6"
      lg="3"
    >

      <rc-overlay
        :show="loading"
        rounded
      >
        <statistic-card-horizontal
          icon="CrosshairIcon"
          :statistic="ordersStatisticsGrouped.inProgress"
          :statistic-title="$t('dashboard.orders.in-progress')"
          color="primary"
        />
      </rc-overlay>

    </b-col>

    <b-col
      cols="12"
      md="6"
      lg="3"
    >

      <rc-overlay
        :show="loading"
        rounded
      >
        <statistic-card-horizontal
          icon="FileTextIcon"
          :statistic="ordersStatisticsGrouped.completedButNotDone"
          :statistic-title="$t('dashboard.orders.completed-but-not-done')"
          color="warning"
        />
      </rc-overlay>

    </b-col>

    <b-col
      cols="12"
      md="6"
      lg="3"
    >

      <rc-overlay
        :show="loading"
        rounded
      >
        <statistic-card-horizontal
          icon="CheckIcon"
          :statistic="ordersStatisticsGrouped.done"
          :statistic-title="$t('dashboard.orders.done')"
          color="success"
        />
      </rc-overlay>

    </b-col>

  </b-row>
</template>
<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import PusherClient from '@/libs/pusher'
import { BCol, BRow } from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  name: 'DispatcherStatistics',
  components: {
    RcOverlay,
    StatisticCardHorizontal,
    BCol,
    BRow,
  },
  data() {
    return {
      loading: true,
      ordersStatisticsGrouped: {
        total: 0,
        completedButNotDone: 0,
        inProgress: 0,
        done: 0,
        toDo: 0,
      },
    }
  },
  computed: {
    ordersStatistics() {
      return this.$store.getters['order/statisticsByTransportAt']
    },
  },
  beforeMount() {
    this.$store.dispatch('order/fetchStatisticsByTransportAt')
      .then(() => {
        this.groupOrderStatistics()
        this.loading = false
      })
    PusherClient.bind('update', 'order', () => {
      this.$store.dispatch('order/fetchStatisticsByTransportAt')
        .then(() => {
          this.groupOrderStatistics()
        })
    })
  },
  beforeDestroy() {
    PusherClient.unbind('update', 'order')
  },
  methods: {
    groupOrderStatistics() {
      this.reset()
      Object.values(this.ordersStatistics).forEach(day => {
      // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(day)) {
          if (key !== 'date') {
            this.ordersStatisticsGrouped[key] += parseInt(value, 10)
          }
        }
      })
    },
    reset() {
      this.ordersStatisticsGrouped = {
        total: 0,
        completedButNotDone: 0,
        inProgress: 0,
        done: 0,
        toDo: 0,
      }
    },
  },
}
</script>
