<template>
  <div>
    <b-row>
      <b-col class="mb-50">
        <h4>
          {{ $t('dashboard.teams.title') }}
        </h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        cols="12"
      >

        <rc-overlay
          :show="loading"
          rounded
        >
          <statistic-card-horizontal
            icon="UsersIcon"
            :statistic="teamsStatistics.available"
            :statistic-title="$t('dashboard.teams.free')"
            color="success"
          />
        </rc-overlay>

      </b-col>

      <b-col
        md="6"
        cols="12"
      >

        <rc-overlay
          :show="loading"
          rounded
        >
          <statistic-card-horizontal
            icon="UsersIcon"
            :statistic="teamsStatistics.total - teamsStatistics.available"
            :statistic-title="$t('dashboard.teams.busy')"
            color="danger"
          />
        </rc-overlay>

      </b-col>

    </b-row>

    <b-row>
      <b-col class="mb-50">
        <h4>
          {{ $t('dashboard.active-teams.title') }}
        </h4>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        v-for="team in activeTeams"
        :key="team.id"
        cols="12"
        md="6"
        xl="4"
      >
        <b-card
          class="team-card"
          :class="{
            'team-card-success': team.status,
            'team-card-danger': !team.status
          }"
        >

          <div>
            <h4 class="mb-1">
              {{ team.teamName.name }}
              <b-button
                v-visible-to-role="['ROLE_DISPATCHER']"
                class="float-right mt-n50 mr-n1"
                variant="flat-primary"
                size="sm"
                :to="{ name: 'team-view', params: { id: team.id } }"
              >
                {{ $t('shared.button.see') }}
              </b-button>
            </h4>
          </div>

          <div v-if="team.id">
            <dl class="row mb-0">
              <dt class="font-bold col-4">
                {{ $t('dashboard.teams.label.paramedic-user') }}
              </dt>
              <dd class="col-8">
                {{ team.paramedicUser.surname }} {{ team.paramedicUser.name }}
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="font-bold col-4">
                {{ $t('dashboard.teams.label.driver-user') }}
              </dt>
              <dd class="col-8">
                {{ team.driverUser.surname }} {{ team.driverUser.name }}
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="font-bold col-4">
                {{ $t('dashboard.teams.label.vehicle') }}
              </dt>
              <dd class="col-8">
                {{ team.vehicle.sideNumber }}
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="font-bold col-4">
                {{ $t('dashboard.teams.label.working-from') }}
              </dt>
              <dd class="col-8">
                {{ transformDatetimeToHumanReadablePretty(team.teamStartedAt) }}
              </dd>
            </dl>
            <dl
              v-if="team.currentOrder"
              class="row mb-0"
            >
              <dt class="font-bold col-4">
                {{ $t('dashboard.teams.label.current-order') }}
              </dt>
              <dd class="col-8">
                <router-link :to="{ name: 'order-view', params: { id: team.currentOrder.id } }">
                  {{ team.currentOrder.fullNumber }}
                </router-link>
                <span>
                  <br>/&nbsp;{{ $t('order.status.' + team.currentOrder.status) }}
                </span>
              </dd>
            </dl>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import PusherClient from '@/libs/pusher'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  name: 'TeamStatistics',
  components: {
    StatisticCardHorizontal,
    BCol,
    BRow,
    BCard,
    BButton,
    RcOverlay,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    teamsStatistics() {
      return { total: 0, available: 0, ...this.$store.getters['team/statisticsCurrent'] }
    },
    activeTeams() {
      return this.$store.getters['team/getActiveTeams']
    },
  },
  mounted() {
    this.init()
    PusherClient.bind('update', 'team', () => this.init())
    PusherClient.bind('update', 'order', () => this.init())
  },
  beforeDestroy() {
    PusherClient.unbind('update', 'team')
    PusherClient.unbind('update', 'order')
    this.$store.commit('team/SET_ACTIVE_TEAMS', [])
  },
  methods: {
    init() {
      this.$store.dispatch('team/fetchStatisticsCurrent')
        .then(() => {
          this.loading = false
        })
      this.$store.dispatch('team/fetchActiveTeams', { currentOnly: 1 })
    },
    transformDatetimeToHumanReadablePretty,
  },
}
</script>

<style lang="scss" >
@import "../../../assets/scss/variables/_variables.scss";

.team-card {
  &.team-card-success {
    border: 0 solid $success;
    border-left-width: 8px;
  }
  &.team-card-danger {
    border: 0 solid $danger;
    border-left-width: 8px;
  }
}
</style>
