<template>
  <b-overlay
    :show="show"
    :no-fade="noFade"
    :variant="isDark ? 'transparent' : 'white'"
    :rounded="rounded"
  >
    <slot />
  </b-overlay>
</template>
<script>

import { BOverlay } from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  name: 'RcOverlay',
  components: {
    BOverlay,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    noFade: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { skin, isDark }
  },
}
</script>
