<template>
  <div>
    <b-row>

      <b-col
        cols="12"
      >
        <b-card
          :title="$t('dashboard.my-team.title') + (team.teamName.name ? ': ' + team.teamName.name : '')"
        >

          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <dl class="row mb-0">
                <dt class="font-bold col-4">
                  {{ $t('dashboard.my-team.label.status') }}
                </dt>
                <dd class="col-8">
                  <b-badge
                    v-if="team.status"
                    variant="light-success"
                  >
                    {{ $t('team.status-switcher.active') }}
                  </b-badge>
                  <b-badge
                    v-else
                    variant="light-danger"
                  >
                    {{ $t('team.status-switcher.inactive') }}
                  </b-badge>
                </dd>
              </dl>

              <dl class="row mb-0">
                <dt class="font-bold col-4">
                  {{ $t('dashboard.my-team.label.paramedic-user') }}
                </dt>
                <dd class="col-8">
                  {{ team.paramedicUser.surname }} {{ team.paramedicUser.name }}
                </dd>
              </dl>

              <dl class="row mb-0">
                <dt class="font-bold col-4">
                  {{ $t('dashboard.my-team.label.driver-user') }}
                </dt>
                <dd class="col-8">
                  {{ team.driverUser.surname }} {{ team.driverUser.name }}
                </dd>
              </dl>

            </b-col>

            <b-col
              cols="12"
              md="6"
            >

              <dl class="row mb-0">
                <dt class="font-bold col-4">
                  {{ $t('dashboard.my-team.label.vehicle') }}
                </dt>
                <dd class="col-8">
                  {{ team.vehicle.sideNumber }}
                </dd>
              </dl>

              <dl class="row mb-0">
                <dt class="font-bold col-4">
                  {{ $t('dashboard.my-team.label.working-from') }}
                </dt>
                <dd class="col-8">
                  {{ transformDatetimeToHumanReadablePretty(team.teamStartedAt) }}
                </dd>
              </dl>

            </b-col>
          </b-row>

        </b-card>
      </b-col>

    </b-row>

  </div>
</template>
<script>
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import {
  BBadge, BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  name: 'TeamStatistics',
  components: {
    BRow,
    BCol,
    BBadge,
    BCard,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
  },
}
</script>
