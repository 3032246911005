<template>
  <div>

    <my-team
      v-if="myTeam.id"
      :team="myTeam"
    />

    <dispatcher-statistics v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER'])" />

    <team-statistics v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER'])" />

  </div>
</template>

<script>
import MyTeam from '@/views/dashboard/components/MyTeam.vue'
import DispatcherStatistics from '@/views/dashboard/components/DispatcherStatistics.vue'
import TeamStatistics from '@/views/dashboard/components/TeamStatistics.vue'
import guard from '@/guard'

export default {
  components: {
    MyTeam,
    DispatcherStatistics,
    TeamStatistics,
  },
  computed: {
    me() {
      return this.$store.state.auth.activeUser
    },
    myTeam() {
      return this.$store.state.auth.userTeam
    },
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
  },
}
</script>
